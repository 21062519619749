import React from 'react';
import { Link } from 'gatsby';

const NotFound = () => (
  <section className="text-gray-700 body-font">
    <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
      <img
        className="h-8 w-auto"
        src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1597218653/PiLogos/logo_2x_eema7k.png"
        alt="PiCortex"
      />
      <h1 className="pt-10 title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
        Sorry
      </h1>
      <img
        className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
        alt="404"
        src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1599216634/PiCortex%20Website/404_gj4wl6.jpg"
      />
      <div className="text-center lg:w-2/3 w-full">
        <p className="mb-8 leading-relaxed">
          Looks like something went wrong on our end. <br />
          Head back to the PiCortex homepage
        </p>
        <div className="flex justify-center">
          <Link
            to="/"
            className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 text-lg leading-6 font-medium rounded-md text-white bg-primaryColor hover:bg-green-600 focus:outline-none focus:border-primaryColor focus:shadow-outline-primaryColor active:bg-primaryColor transition ease-in-out duration-150"
          >
            Go back to home
          </Link>
        </div>
      </div>
    </div>
  </section>
);
export default NotFound;
