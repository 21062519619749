import React from 'react';

import PageLayout from '@global/page-layout';
import NotFound from '@containers/NotFound';
import SEO from '@global/seo';

const NotFoundPage = () => (
  <PageLayout hideHeader>
    <SEO title="404: Not found" />
    <NotFound />
  </PageLayout>
);

export default NotFoundPage;
